import React from 'react';
import Presenter from './presenter';
import { IContainerProps, IContainerState, IDispositivo } from './types';
import { API } from 'aws-amplify';
import Axios from 'axios';
import dayjs from 'dayjs';
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);
dayjs.extend(timezone);

const clienteInitialState = {
  nombres: '',
  apellidos: '',
  telefono_1: '',
  email: '',
  ciruc: '',
  ciudad: '',
}

const dispositivoInitialState: IDispositivo = {
  imei: '',
  marca: '',
  modelo: '',
  alias_marca: '',
  alias_modelo: '',
  emnifyId: '',
  simId: '',
  serial: '',
  iccid: '',
  instalador: '',
  comandos: {
    puertas: '',
    bloqueo: '',
    desbloqueo: '',
    puertas_gprs: '',
    bloqueo_gprs: '',
    desbloqueo_gprs: '',
    verificar: ''
  },
  respuesta_exitosa: ''
}

const vehiculoInitialState = {
  id: 0,
  marca: '',
  modelo: '',
  anio: '',
  placa: '',
  chasis: '',
  mt: '',
  ramv: '',
  numero: '',
  kilometraje: '',
  notas: '',
  bloqueoMotor: false,
  aperturaPuertas: false,
  botonPanico: false,
  fecha_expiracion: '',
  fecha_instalacion: '',
  solicitud_id: '',
}

class Container extends React.Component<IContainerProps, IContainerState>{
  constructor(props: any) {
    super(props);
    this.state = {
      loading: '',
      solicitudId: '',
      solicitudClave: '',
      cliente: clienteInitialState,
      dispositivo: dispositivoInitialState,
      vehiculo: vehiculoInitialState,
    }
  }

  setNumeroInstalacion = (value: string) => {
    this.setState({
      solicitudId: value,
      errorSolicitud: undefined,
    });
  };

  setPassword = (value: string) => {
    this.setState({
      solicitudClave: value,
      errorSolicitud: undefined,
    });
  };

  setKilometraje = (value: string) => {
    this.setState({
      vehiculo: {
        ...this.state.vehiculo,
        kilometraje: value,
      },
    });
  };

  setEmail = (value: string) => {
    this.setState({
      cliente: {
        ...this.state.cliente,
        email: value,
      },
    });
  };
  setPlaca = (value: string) => {
    this.setState({
      vehiculo: {
        ...this.state.vehiculo,
        placa: value,
      },
    });
  };
  setChasis = (value: string) => {
    this.setState({
      vehiculo: {
        ...this.state.vehiculo,
        chasis: value,
      },
    });
  };
  setNotas = (value: string) => {
    this.setState({
      vehiculo: {
        ...this.state.vehiculo,
        notas: value,
      },
    });
  };
  setInstalador = (value: string) => {
    this.setState({
      dispositivo: {
        ...this.state.dispositivo,
        instalador: value,
      },
      errorConfigurar: undefined,
    });
  };
  setNumeroSerie = (value: string) => {
    this.setState({
      dispositivo: {
        ...this.state.dispositivo,
        serial: value,
        simId: "",
      },
      errorObtnerDispositivo: undefined,
    });
  };
  setBotonDePanico = (value: boolean) => {
    this.setState({
      vehiculo: {
        ...this.state.vehiculo,
        boton_panico: value,
      },
    });
  };
  setAperturaDePuertas = (value: boolean) => {
    this.setState({
      vehiculo: {
        ...this.state.vehiculo,
        apertura_puertas: value,
      },
    });
  };
  setBloqueoMotor = (value: boolean) => {
    this.setState({
      vehiculo: {
        ...this.state.vehiculo,
        bloqueo_motor: value,
      },
    });
  };



  onObtenerSolicitud = () => {
    if (this.state.solicitudId !== "" && this.state.solicitudClave !== "") {
      let nombres = "";
      let apellidos = "";
      let telefono = "";
      this.setState(
        {
          loading: "custormer",
        },
        async () => {
          try {
            const resultSolicitud = await API.get(
              `${process.env.REACT_APP_ENV}-instaladores`,
              `/solicitudes?id=${this.state.solicitudId}&clave=${this.state.solicitudClave}`,
              {}
            );
            console.log("resultado api", resultSolicitud);
            const solicitud = resultSolicitud[0];
            // console.log(customer);
            if (solicitud) {
              if (solicitud.estado === "finalizado") {
                throw new Error("La solicitud ha sido finalizada!");
              } else if (solicitud.estado === "instalado") {
                const resultVehiculo = await API.get(
                  `${process.env.REACT_APP_ENV}-instaladores`,
                  `/vehiculos?solicitudId=${solicitud.id}&claveRevision=${solicitud.clave}`,
                  {}
                );
                // console.log("Vehiculo Instalado: ", resultVehiculo);
                const resultCliente = await await API.get(
                  `${process.env.REACT_APP_ENV}-instaladores`,
                  `/usuarios?id=${resultVehiculo[0].usuario_id}`,
                  {}
                );

                const deviceFound = await API.get(
                  `${process.env.REACT_APP_ENV}-instaladores`,
                  `/dispositivos?serial=${resultVehiculo[0].serial_number}`,
                  {}
                );
                console.log("dispositivo obtenido: ", deviceFound)
                this.setState({
                  vehiculo: {
                    ...resultVehiculo[0],
                    id: resultVehiculo[0].id,
                    apertura_puertas: resultVehiculo[0].apertura_puertas === 1 ? true : false,
                    bloqueo_motor: resultVehiculo[0].bloqueo_motor === 1 ? true : false,
                    boton_panico: resultVehiculo[0].boton_panico === 1 ? true : false,
                    notas: solicitud.notas,
                    kilometraje: solicitud.kilometraje,
                    fecha_instalacion: deviceFound.fechaInstalacion
                  },
                  cliente: resultCliente[0],
                  solicitud,
                  dispositivo: {
                    ...this.state.dispositivo,
                    id: deviceFound.id,
                    serial: deviceFound.serial,
                    imei: deviceFound.imei,
                    marca: deviceFound.marca,
                    modelo: deviceFound.modelo,
                    alias_marca: deviceFound.alias_marca,
                    alias_modelo: deviceFound.alias_modelo,
                    emnifyId: deviceFound.emnifyId,
                    emnifyToken: deviceFound.emnifyToken,
                    orionToken: deviceFound.orion_token,
                    floliveToken:deviceFound.flolive_token,
                    marcaSim:deviceFound.marcaSim,
                    simId: deviceFound.simId,
                    iccid: deviceFound.iccid,
                    comandos: {
                      puertas: deviceFound.comandoPuertas,
                      bloqueo: deviceFound.comandoBloqueo,
                      desbloqueo: deviceFound.comandoSinBloqueos,
                      puertas_gprs: deviceFound.comandoPuertasGPRS,
                      bloqueo_gprs: deviceFound.comandoBloqueoGPRS,
                      desbloqueo_gprs: deviceFound.comandoSinBloqueosGPRS,
                      verificar: deviceFound.comando_verificar,
                    },
                    respuesta_exitosa: deviceFound.respuesta_exitosa,
                    respuesta_fallida: deviceFound.respuesta_fallida,
                    instalador: resultVehiculo[0].responsable_instalacion
                  },
                  loading: "",
                }, () => { console.log(this.state) })

              } else {
                nombres = solicitud.nombres;
                apellidos = solicitud.apellidos;
                if (solicitud.telefono_celular[0] === "+") {
                  telefono = solicitud.telefono_celular.substring(1);
                } else if (solicitud.telefono_celular[0] === "0") {
                  telefono = "593" + solicitud.telefono_celular.substring(1);
                } else {
                  telefono = solicitud.telefono_celular;
                }
                if (solicitud.placa && solicitud.placa !== "") {
                  const withNoSpaces = solicitud.placa.replace(/\s+/g, "");
                  this.setPlaca(withNoSpaces);
                }
                if (solicitud.chasis && solicitud.chasis !== "") {
                  const withNoSpaces = solicitud.chasis.replace(/\s+/g, "");
                  this.setChasis(withNoSpaces);
                }
                const dateExpire = dayjs(new Date())
                  .add(
                    Number(solicitud.tiempo_servicio.split(" ")[0]),
                    "year"
                  )
                  .format("YYYY-MM-DDTHH:mm");

                this.setState(
                  {
                    cliente: {
                      nombres: solicitud.nombres || "",
                      apellidos: solicitud.apellidos || "",
                      telefono_1: telefono,
                      email: solicitud.email || "",
                      email_factura_mt: solicitud.email_factura_mt,
                      ciruc: solicitud.ciruc || "",
                      ciudad: solicitud.ciudad || "",
                    },
                    solicitud: {
                      emailFinanciera: solicitud.email_financiera || "",
                      ...solicitud,
                    },
                    vehiculo: {
                      ...this.state.vehiculo,
                      placa: solicitud.placa || "",
                      chasis: solicitud.chasis || "",
                      mt: solicitud.mt || "",
                      marca: solicitud.marca || "",
                      modelo: solicitud.modelo || "",
                      anio: solicitud.anio || "",
                      fecha_expiracion: dateExpire || "",
                    },
                    loading: "",
                  },
                  () => console.log(this.state)
                );
              }
            } else {
              throw new Error("Credenciales Incorrectas!");
            }
          } catch (error) {
            console.log("error", error);
            this.setState({
              loading: "",
              // @ts-ignore
              errorSolicitud: error.toString(),
            });
          }
        }
      );
    } else {
      this.setState({
        errorSolicitud: "Error: Debe ingresar el todos los campos",
      });
    }
  };

  onObtenerDispositivo = () => {
    this.setState({ loading: "device" }, async () => {
      try {
        const deviceFound = await API.get(
          `${process.env.REACT_APP_ENV}-instaladores`,
          `/dispositivos?serial=${this.state.dispositivo.serial}`,
          {}
        );
        console.log("Dispositivo: ", deviceFound);
        if (deviceFound) {
          if (deviceFound.emnifyId && deviceFound.emnifyId !== "") {
            throw new Error(
              "El dispositivo ya ha sido instalado en otro vehículo"
            );
          } else if (!deviceFound.simId) {
            throw new Error(
              "El dispositivo no tiene un chip asignado en el sistema"
            );
          } else {
            this.setState({
              dispositivo: {
                ...this.state.dispositivo,
                imei: deviceFound.imei,
                marca: deviceFound.marca,
                modelo: deviceFound.modelo,
                alias_marca: deviceFound.alias_marca,
                alias_modelo: deviceFound.alias_modelo,
                emnifyId: deviceFound.emnifyId,
                emnifyToken: deviceFound.emnifyToken,
                orionToken: deviceFound.orion_token,
                floliveToken:deviceFound.flolive_token,
                simId: deviceFound.simId,
                iccid: deviceFound.iccid,
                marcaSim:deviceFound.marcaSim,
                comandos: {
                  puertas: deviceFound.comandoPuertas,
                  bloqueo: deviceFound.comandoBloqueo,
                  desbloqueo: deviceFound.comandoSinBloqueos,
                  puertas_gprs: deviceFound.comandoPuertasGPRS,
                  bloqueo_gprs: deviceFound.comandoBloqueoGPRS,
                  desbloqueo_gprs: deviceFound.comandoSinBloqueosGPRS,
                  verificar: deviceFound.comando_verificar,
                },
                respuesta_exitosa: deviceFound.respuesta_exitosa,
                respuesta_fallida: deviceFound.respuesta_fallida,
                instalador: "",
              },
              loading: "",
            }, () => console.log("Dispositivo en local", this.state.dispositivo));
          }
        } else {
          throw new Error("Dispositivo no encontrado");
        }
      } catch (error) {
        this.setState({
          dispositivo: {
            ...dispositivoInitialState,
            serial: this.state.dispositivo.serial,
          },
          // @ts-ignore
          errorObtnerDispositivo: error.toString(),
          loading: "",
        });
      }
    });
  };

  onConfigurarDispositivo = () => {
    this.setState({ loading: "setup" }, async () => {
      if (this.state.solicitud) {
        if (
          this.state.vehiculo.kilometraje !== "" &&
          this.state.cliente.email !== ""
        ) {
          const patronPlaca = /^[A-Za-z]{3}[0-9]{4}$/;
          const patronPlacaMoto = /^[A-Za-z]{2}[0-9]{3}[A-Za-z]{1}$/;
          if (
            (this.state.vehiculo.placa !== "" &&
              (patronPlaca.test(this.state.vehiculo.placa) ||
                patronPlacaMoto.test(this.state.vehiculo.placa))) ||
            (this.state.vehiculo.placa === "" &&
              this.state.vehiculo.chasis !== "")
          ) {
            // get ramv
            // TODO
            // get avaluo
            let valorAvaluo = 0;
            var carFromSRI;
            if (
              this.state.vehiculo.placa !== "" &&
              patronPlaca.test(this.state.vehiculo.placa)
            ) {
              try {
                const avaluoResult = await Axios.get(
                  `https://o5j42hbf20.execute-api.us-east-2.amazonaws.com/dev/get_avaluo?placa=${this.state.vehiculo.placa.toUpperCase()}`
                );
                const avaluo = avaluoResult.data;
                if (avaluo.estado.codigo === "OK") {
                  valorAvaluo = avaluo.personaVehiculo.precioVentaPublico;
                }
              } catch (error) {
                console.log(
                  `Error Avaluo. placa: ${this.state.vehiculo.placa}`,
                  error
                );
              }
              // *****************************
              // obtener ramv
              try {
                const resultSRI = await Axios.get(
                  `https://srienlinea.sri.gob.ec/movil-servicios/api/v1.0/matriculacion/valor/${this.state.vehiculo.placa}`,
                  {
                    timeout: 5000,
                  }
                );
                console.log("Info obtenida del sri", resultSRI);
                carFromSRI = resultSRI.data;
              } catch (error) {
                console.log(
                  `Error SRI placa: ${this.state.vehiculo.placa}`,
                  error
                );
              }
              // fin obtener ramv
            }
            try {
              const resultDevice = await API.post(
                `${process.env.REACT_APP_ENV}-instaladores`,
                `/dispositivos`,
                {
                  body: {
                    ...this.state.dispositivo,
                    emnifyToken: this.state.dispositivo.emnifyToken,
                    placa: this.state.vehiculo.placa.toUpperCase(),
                    mt: this.state.vehiculo.mt.toUpperCase(),
                    ramv: carFromSRI ? carFromSRI.camvCpn : null,
                    marcaVehiculo: this.state.vehiculo.marca,
                    modeloVehiculo: this.state.vehiculo.modelo,
                    anioVehiculo: this.state.vehiculo.anio,
                    nombres: this.state.cliente.nombres,
                    apellidos: this.state.cliente.apellidos,
                    email: this.state.cliente.email,
                    emailFacturaMt: this.state.cliente.email_factura_mt,
                    ciruc: this.state.cliente.ciruc,
                    kilometraje: this.state.vehiculo.kilometraje,
                    telefono: this.state.cliente.telefono_1,
                    dateExpire: this.state.vehiculo.fecha_expiracion,
                    instalador: this.state.dispositivo.instalador,
                    alias_marca: this.state.dispositivo.alias_marca,
                    alias_modelo: this.state.dispositivo.alias_modelo,
                    notas: this.state.vehiculo.notas,
                    ciudad: this.state.cliente.ciudad,
                    emailFinanciera: this.state.solicitud.emailFinanciera,
                    chasis: this.state.vehiculo.chasis.toUpperCase(),
                    solicitudId: this.state.solicitud.id,
                    valorAvaluo,
                    botonDePanico: this.state.vehiculo.boton_panico,
                    aperturaDePuertas: this.state.vehiculo.apertura_puertas,
                    bloqueoMotor: this.state.vehiculo.bloqueo_motor,
                    claveRevision: this.state.solicitudClave,
                    agencia: this.state.solicitud.agencia,
                    tiempo_servicio: this.state.solicitud.tiempo_servicio,
                    vendedor: this.state.solicitud.vendedor
                  },
                }
              );
              // console.log("Result device", resultDevice)
              this.setState(
                {
                  dispositivo: {
                    ...this.state.dispositivo,
                    id: resultDevice.id,
                    emnifyId: resultDevice.emnifyId,
                  },
                  cliente: {
                    ...this.state.cliente,
                  },
                  vehiculo: {
                    ...this.state.vehiculo,
                    fecha_instalacion: resultDevice.fecha_instalacion
                  },
                  solicitud: {
                    ...this.state.solicitud,
                    estado: "instalado",
                  },
                  loading: "",
                },
                () => {
                  alert("Comandos de configuración enviados exitosamente!");
                  // console.log("device after installation", this.state.dispositivo)
                }
              );
            } catch (error) {
              console.log("Error AWS config", error);
              this.setState({
                errorConfigurar:
                  "Ocurrio un error al configurar el dispositivo.",
                loading: "",
              });
            }
          } else {
            this.setState({
              errorConfigurar:
                'La placa de cumplir con el formato: "AAA0123" ó "JJ123A".',
              loading: "",
            });
          }
        } else {
          this.setState({
            errorConfigurar: "Debe llenar todos los campos.",
            loading: "",
          });
        }
      }
    });
  };

  onEliminarInstalacion = async () => {
    try {
      const resultDevice = await API.del(
        `${process.env.REACT_APP_ENV}-instaladores`,
        `/solicitudes`,
        {
          body: {
            device_id: this.state.dispositivo?.id,
            emnify_id: this.state.dispositivo.emnifyId,
            solicitud_id: this.state.solicitud?.id,
            emnify_token: this.state.dispositivo.emnifyToken,
            sim_id: this.state.dispositivo.simId
          },
        }
      );
      alert("Se ha eliminado la Instalación");
      window.location.reload();
    } catch (error) {
      alert("Ocurrió un error al eliminar la instalación, contactese con soporte.");
      console.error(error)
    }

  }

  onUpdateEstado = (sms: any) => {
    this.state.solicitud && this.setState({
      solicitud: {
        ...this.state.solicitud,
        estado: 'configurado',

      },
      dispositivo: {
        ...this.state.dispositivo,
        sms
      }
    })
  }

  onUpdateFuncionesInstaladas = (opcion: string) => {
    if (opcion === 'puertas') {
      this.state.solicitud && this.setState({
        solicitud: {
          ...this.state.solicitud,
          prueba_puertas: true
        }
      }, () => console.log(this.state.solicitud))
    } else if (opcion === 'bloqueo') {
      this.state.solicitud && this.setState({
        solicitud: {
          ...this.state.solicitud,
          prueba_bloqueo: true,
          prueba_desbloqueo: false,
        }
      }, () => console.log(this.state.solicitud))

    } else if (opcion === 'desbloqueo') {
      this.state.solicitud && this.setState({
        solicitud: {
          ...this.state.solicitud,
          prueba_desbloqueo: true
        }
      }, () => console.log(this.state.solicitud))
    }
  }

  onEnviarComandoGPRS = async () => {
    console.log("Dispositivo Id", this.state.dispositivo.id);
    try {
      const resultDevice = await API.post(
        `${process.env.REACT_APP_ENV}-instaladores`,
        `/gpswox`,
        { body: { device_id: this.state.dispositivo.id } }
      );
      console.log("conexion", resultDevice)
      if (resultDevice.error) {
        if (resultDevice.error[0].includes("GPRS")) {
          alert("ERROR: El dispositivo no tiene conexión con el servidor.");
        } else {
          alert(resultDevice.error[0]);
        }
      } else {
        // alert(resultDevice.message)
        alert("OK: El dispositivo tiene conexión con el servidor.");
      }
    } catch (error) {
      console.log(error);
    }
  };

  onFinalizarInstalacion = () => {
    // Enviar zap
    if (this.state.dispositivo.instalador?.trim() !== "") {

      const zapBodyRequest = {
        solicitud_id: this.state.solicitud?.id,
        fecha_instalacion: this.state.vehiculo.fecha_instalacion,
        cliente: `${this.state.cliente.nombres} ${this.state.cliente.apellidos}`,
        ciruc: this.state.cliente.ciruc,
        ciudad: this.state.cliente.ciudad,
        emailCliente: this.state.cliente.email_factura_mt,
        usuario_id: this.state.cliente.id,
        vehiculo_id: this.state.vehiculo.id,
        dispositivo_id: this.state.dispositivo.id,
        placa: this.state.vehiculo.placa,
        ramv: this.state.vehiculo.ramv,
        chasis: this.state.vehiculo.chasis,
        mt: this.state.vehiculo.mt,
        nombres: this.state.cliente.nombres,
        apellidos: this.state.cliente.apellidos,
        agencia: this.state.solicitud?.agencia,
        marca_vehiculo: this.state.vehiculo.marca,
        modelo_vehiculo: this.state.vehiculo.modelo,
        anio_vehiculo: this.state.vehiculo.anio,
        kilometraje: this.state.vehiculo?.kilometraje,
        apertura_puertas: this.state.vehiculo.apertura_puertas,
        bloqueo_motor: this.state.vehiculo.bloqueo_motor,
        boton_panico: this.state.vehiculo.boton_panico,
        instalador: this.state.dispositivo.instalador,
        emnify_id: this.state.dispositivo.emnifyId,
        emnify_token: this.state.dispositivo.emnifyToken,
        comandos: this.state.dispositivo.comandos,
        serie: this.state.dispositivo.serial,
        iccid: this.state.dispositivo.iccid,
        notas: this.state.vehiculo.notas,
        dispositivo: `${this.state.dispositivo.marca} ${this.state.dispositivo.modelo}`,
        fecha_expiracion: this.state.vehiculo.fecha_expiracion,
        tiempo_servicio: this.state.solicitud?.tiempo_servicio,
        vendedor: this.state.solicitud?.vendedor,
        sms: this.state.dispositivo.sms
      };

      this.setState({ loading: 'setup' }, async () => {
        try {
          const resultSolicitud = await API.put(
            `${process.env.REACT_APP_ENV}-instaladores`,
            `/solicitudes`, {
            body: {
              ...zapBodyRequest
            }
          }
          );
          console.log(resultSolicitud);
          alert("La instalación ha finalizado.")
          window.location.reload();
        } catch (error) {
          console.error('Error al enviar al zap:', error)
        }
        finally {
          this.setState({ loading: '' })
        }

      })

    } else {
      this.setState({ errorConfigurar: "Debe ingresar su nombre" });
    }

  }

  render() {
    return (
      <Presenter
        {...this.props}
        {...this.state}
        setNumeroInstalacion={this.setNumeroInstalacion}
        setPassword={this.setPassword}
        setKilometraje={this.setKilometraje}
        setEmail={this.setEmail}
        setBotonDePanico={this.setBotonDePanico}
        setAperturaDePuertas={this.setAperturaDePuertas}
        setBloqueoMotor={this.setBloqueoMotor}
        setPlaca={this.setPlaca}
        setChasis={this.setChasis}
        setNotas={this.setNotas}
        setInstalador={this.setInstalador}
        setNumeroSerie={this.setNumeroSerie}
        onObtenerSolicitud={this.onObtenerSolicitud}
        onObtenerDispositivo={this.onObtenerDispositivo}
        onConfigurarDispositivo={this.onConfigurarDispositivo}
        onEnviarComandoGPRS={this.onEnviarComandoGPRS}
        onUpdateEstado={this.onUpdateEstado}
        onUpdateFuncionesInstaladas={this.onUpdateFuncionesInstaladas}
        onFinalizarConfiguracion={this.onFinalizarInstalacion}
        onEliminarInstalacion={this.onEliminarInstalacion}
      />
    );
  }
}

export default Container;