import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { IProps } from './types';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Button, Grid } from '@material-ui/core';
import { API } from 'aws-amplify';
import dayjs from 'dayjs';

const Main = (props: IProps) => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<{ message: string }| null>(null);
    useEffect(() => {
        let timer:any;
        console.log("data",data)
        if(data == null){
            console.log("entra")
            setLoading(true);
            timer = setInterval(() => {
            obtenerMensajes();
        }, 30000);
        }
        
        
        
        const obtenerMensajes = async () => {
            try {
                let smsArray: any;
                console.log("dispositivo", props.dispositivo)


                if(props.dispositivo.marcaSim === "EMNIFY"){
                    const mensajesEnviadosResult = await Axios.get(`https://cdn.emnify.net/api/v1/endpoint/${props.dispositivo?.emnifyId}/sms`, {
                        headers: {
                            'Authorization': `Bearer ${props.dispositivo.emnifyToken}`,
                            'Content-Type': 'application/json'
                        }
                    })
                    smsArray = mensajesEnviadosResult.data;
                }else if(props.dispositivo.marcaSim === "ORION"){
                    const newSmsArray: any = [];
                    const mensajesEnviadosResult = await Axios.get(`https://www.api.myorion.co/api/sms/history?initialDate=${dayjs().add(-7, "d").format("YYYY-MM-DD")}=&finalDate=${dayjs().add(1, "d").format("YYYY-MM-DD")}`,
                        {
                            headers: {
                                'Authorization': `Bearer ${props.dispositivo.orionToken}`,
                                'Content-Type': 'application/json',
                                'Accept': '*/*'
                            }
                        }
                    );
                    console.log("Orion Result: ", mensajesEnviadosResult.data);
                    const smsArrayFiltered = mensajesEnviadosResult.data.SMSList.filter((sms: any) => sms.iccids.find((iccid: string) => iccid === props.dispositivo.emnifyId));
                    smsArrayFiltered.map((sms: any) => {
                        newSmsArray.push({
                            submit_date: sms.date,
                            delivery_date: sms.date,
                            payload: sms.smsType === 'SMS MO' ? utf16ToBase64(sms.message) : sms.message,
                            status: { id: 4, description: "Delivered" },
                            sms_type: { id: 1, description: "SMS" }
                        });
                    });
                    smsArray = newSmsArray;
                }else {
                    const newSmsArray: any = [];
                    const resultFloliveSMS = await await API.get(
                        `${process.env.REACT_APP_ENV}-instaladores`,
                        `/flolive?iccid=${props.dispositivo.iccid}`,
                        {}
                      );
                      resultFloliveSMS.map((sms: any) => {
                        newSmsArray.push({
                            submit_date: sms.created_at,
                            delivery_date: sms.updated_at,
                            payload:  sms.contenido,
                            status: { id: 4, description: "Delivered" },
                            sms_type: { id: 1, description: "SMS" }
                        });
                    });
                    smsArray= newSmsArray;
                    console.log("flolive sms", resultFloliveSMS)
                }

                
                console.log('smsArray', smsArray);
                const smsFallido: { payload: string } = smsArray.find((sms: any) => sms.payload.includes(props.dispositivo.respuesta_fallida));
                const smsExitoso: { payload: string } = smsArray.find((sms: any) => (sms.payload.includes(props.dispositivo.respuesta_exitosa)));
                console.log('smsFallido', smsFallido);
                console.log('smsExitoso', smsExitoso);
                if (smsExitoso) {
                    console.log("ENTRA AQUI")
                    clearInterval(timer);
                    setData({ message: smsExitoso.payload });                    
                    const smsEnviadoYEntregado: any = smsArray.find((sms: { submit_date: string, delivery_date: string; payload: string, status: { id: number, description: string }, sms_type: { id: number, description: string } }) => sms.sms_type.id === 1 && sms.status.id === 4)
                    console.log(smsEnviadoYEntregado)
                    props.onUpdateEstado && props.onUpdateEstado(smsEnviadoYEntregado);
                    setLoading(false);
                } else {
                    if (smsFallido && smsArray.filter((sms: { payload: string }) => sms.payload.includes("SOS1:")).length > 0 && (smsArray.filter((sms: { payload: string }) => sms.payload.includes(props.dispositivo.comandos.verificar)).length < 3)) {

                        if(props.dispositivo.marcaSim === "EMNIFY"){
                            await Axios.post(`https://cdn.emnify.net/api/v1/endpoint/${props.dispositivo?.emnifyId}/sms`, {
                                "source_address": 2624834189,
                                "payload": props.dispositivo.comandos.verificar
                            }, {
                                headers: {
                                    'Authorization': `Bearer ${props.dispositivo.emnifyToken}`,
                                    'Content-Type': 'application/json'
                                }
                            })
                            
                        }else if (props.dispositivo.marcaSim === "ORION" ){
                            const resultSMS = await Axios.post(`https://www.api.myorion.co/api/sms/mt`, {
                                "to_ICCID": [
                                    props.dispositivo.emnifyId,
                                ],
                                "message": props.dispositivo.comandos.verificar
                            }, {
                                headers: {
                                    'Authorization': `Bearer ${props.dispositivo.orionToken}`,
                                    'Content-Type': 'application/json',
                                    'Accept': '*/*'
                                }
                            });

                            console.log('El comando ha sido enviado.', resultSMS);
                        }else {

                        }

                            
                            
                        
                    }
                }
            } catch (error) {
                console.log('AxiosError', error);
                // setLoading(false)
                // clearInterval(timer);
            }
        }
        return () => clearInterval(timer);
    }, [props.dispositivo.serial, props.dispositivo.emnifyId, props.dispositivo.emnifyToken, props]);
    return (
        <div style={{ marginTop: 20, marginBottom: 10 }}>
            {loading ?
                <Container>
                    <span>Procesando la configuración del dispositivo. Por favor espere</span>
                    <CircularProgress color="secondary" />
                </Container> :
                <Container>
                    <span>Equipo listo para realizar pruebas.</span>
                    <Grid container
                        direction="row"
                        justifyContent="space-evenly"
                        alignItems="center"
                        style={{
                            marginTop: 10,
                            height: 100
                        }}
                    >
                        {props.vehiculo.apertura_puertas && <Button variant={"contained"} onClick={async () => {
                            if (props.dispositivo && props.dispositivo.simId === '') {
                                alert('Error: Primero debe buscar un Dispositivo')
                                // setErrorMessage('Primero debe buscar un Dispositivo')
                                return;
                            }
                            try {
                                const result = await API.post(`${process.env.REACT_APP_ENV}-instaladores`, `/operaciones`, {
                                    body: {
                                        operation: "send_command",
                                        device_id: props.dispositivo.id,
                                        emnify_id: props.dispositivo.emnifyId,
                                        user_api_hash: `this.props.authUser.user_api_hash`,
                                        emnfiy_auth_token: props.dispositivo.emnifyToken,
                                        orion_token: props.dispositivo.orionToken,
                                        marcaSim:props.dispositivo.marcaSim,
                                        flolive_token:props.dispositivo.floliveToken,
                                        commands: {
                                            sms: props.dispositivo.comandos.puertas,
                                            gprs: props.dispositivo.comandos.puertas_gprs
                                        },
                                        description: "abrir puertas",
                                    }
                                });
                                if (result.message.includes("created")) {
                                    alert('El comando de apertura de puertas ha sido enviado.');
                                } else if (result.message.includes("success")) {
                                    alert('Puertas abiertas.');
                                }
                                props.onUpdateFuncionesInstaladas && props.onUpdateFuncionesInstaladas('puertas');
                            } catch (error) {
                                console.log('AxiosError', error);
                                alert(`Error al enviar el comando: ${error}`)
                            }
                        }}>Abrir Puertas</Button>}
                        {props.vehiculo.bloqueo_motor && <><Button variant={"contained"} onClick={async () => {
                            if (props.dispositivo.simId === '') {
                                alert('Error: Primero debe buscar un Dispositivo')
                                return;
                            }
                            try {
                                const result = await API.post(`${process.env.REACT_APP_ENV}-instaladores`, `/operaciones`, {
                                    body: {
                                        operation: "send_command",
                                        device_id: props.dispositivo.id,
                                        emnify_id: props.dispositivo.emnifyId,
                                        user_api_hash: `this.props.authUser.user_api_hash`,
                                        emnfiy_auth_token: props.dispositivo.emnifyToken,
                                        orion_token: props.dispositivo.orionToken,
                                        flolive_token:props.dispositivo.floliveToken,
                                        marcaSim:props.dispositivo.marcaSim,
                                        commands: {
                                            sms: props.dispositivo.comandos.bloqueo,
                                            gprs: props.dispositivo.comandos.bloqueo_gprs
                                        },
                                        description: "bloquear motor"
                                    }
                                });
                                console.log(result);
                                if (result.message.includes("created")) {
                                    alert("El comando de bloqueo ha sido enviado.\nEste comando se ejecutará una vez que el dispositivo reciba el comando. Si el comando no se recibe hasta dentro de 1 hora la acción será cancelada. Te recomendamos mover el vehículo un poco para optimizar la recepción del comando.");
                                } else if (result.message.includes("success")) {
                                    alert('El motor ha sido bloqueado.');
                                } else {
                                    const arrayMessage = result.message.split(" ");
                                    const time_left = 60 - Number(arrayMessage[1]);
                                    if (window.confirm(`Operación Pendiente\nYa has enviado este comando recientemente. Este comando tratará de enviarse por ${time_left} minutos más. Si deseas esperar a que se envie el comando presiona Aceptar, o presiona Cancelar para eliminar el comando en cola.`)) {

                                    } else {
                                        try {
                                            const resultDelete = await API.post(`${process.env.REACT_APP_ENV}-instaladores`, `/operaciones`, {
                                                body: {
                                                    operation: "delete_command",
                                                    sms: result.sms,
                                                    emnfiy_auth_token: props.dispositivo.emnifyToken,
                                                    description: "eliminar comando",
                                                    marcaSim:props.dispositivo.marcaSim,
                                                }
                                            });
                                            alert("El comando ha sido eliminado")
                                        } catch (e) {
                                            alert(`Error, ${e}`)
                                        }
                                    }
                                }
                                props.onUpdateFuncionesInstaladas && props.onUpdateFuncionesInstaladas('bloqueo');
                            } catch (error) {
                                alert(`Error al enviar el comando\n, ${error} Por favor intentelo mas tarde.`);
                            }
                        }}>Bloquear Motor</Button>
                            <Button variant={"contained"} onClick={async () => {
                                if (props.dispositivo && props.dispositivo.simId === '') {
                                    alert('Error: Primero debe buscar un Dispositivo')
                                    return;
                                }
                                try {
                                    const result = await API.post(`${process.env.REACT_APP_ENV}-instaladores`, `/operaciones`, {
                                        body: {
                                            operation: "send_command",
                                            device_id: props.dispositivo.id,
                                            emnify_id: props.dispositivo.emnifyId,
                                            user_api_hash: `this.props.authUser.user_api_hash`,
                                            emnfiy_auth_token: props.dispositivo.emnifyToken,
                                            orion_token: props.dispositivo.orionToken,
                                            flolive_token:props.dispositivo.floliveToken,
                                            marcaSim:props.dispositivo.marcaSim,
                                            commands: {
                                                sms: props.dispositivo.comandos.desbloqueo,
                                                gprs: props.dispositivo.comandos.desbloqueo_gprs
                                            },
                                            description: "desbloquear motor"
                                        }
                                    });
                                    console.log(result);
                                    if (result.message.includes("created")) {
                                        alert("El comando de desbloqueo ha sido enviado.\nEste comando se ejecutará una vez que el dispositivo reciba el comando. Si el comando no se recibe hasta dentro de 1 hora la acción será cancelada. Te recomendamos mover el vehículo un poco para optimizar la recepción del comando.");
                                    } else if (result.message.includes("success")) {
                                        alert('El motor ha sido desbloqueado.');
                                    } else {
                                        const arrayMessage = result.message.split(" ");
                                        const time_left = 60 - Number(arrayMessage[1]);
                                        if (window.confirm(`Operación Pendiente\nYa has enviado este comando recientemente. Este comando tratará de enviarse por ${time_left} minutos más. Si deseas esperar a que se envie el comando presiona Aceptar, o presiona Cancelar para eliminar el comando en cola.`)) {

                                        } else {
                                            try {
                                                const resultDelete = await API.post(`${process.env.REACT_APP_ENV}-instaladores`, `/operaciones`, {
                                                    body: {
                                                        operation: "delete_command",
                                                        sms: result.sms,
                                                        emnfiy_auth_token: props.dispositivo.emnifyToken,
                                                        description: "eliminar comando",
                                                        marcaSim:props.dispositivo.marcaSim,
                                                    }
                                                });
                                                alert("El comando ha sido eliminado")
                                            } catch (e) {
                                                alert(`Error, ${e}`)
                                            }
                                        }
                                    }
                                    props.onUpdateFuncionesInstaladas && props.onUpdateFuncionesInstaladas('desbloqueo');
                                } catch (error) {
                                    alert(`Error al enviar el comando\n, ${error} Por favor intentelo mas tarde.`);
                                }
                            }}>Quitar Bloqueos</Button></>}
                        <Button variant={"contained"} onClick={() => {
                            if (props.vehiculo.id === undefined) {
                                alert('Error: Primero debe realizar la configuracion.')
                                return;
                            }
                            props.onEnviarComandoGPRS();
                        }}>Conexión Servidor</Button>
                    </Grid>
                </Container>
            }
        </div>
    );
}

export default Main;

const utf16ToBase64 = (str: string) => {
    const utf16String = str;
    // Convertir UTF-16 a un array de bytes
    const utf16Array = [];
    for (let i = 0; i < utf16String.length; i++) {
        utf16Array.push(utf16String.charCodeAt(i));
    }
    // Convertir el array de bytes a una cadena UTF-8
    const utf8String = String.fromCharCode(...utf16Array.filter((_, i) => i % 2 !== 0));
    return utf8String
}