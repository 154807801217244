import React, { useEffect, useState } from 'react';
// import Axios from 'axios';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from '@material-ui/core/InputLabel';
import LinearProgress from '@material-ui/core/LinearProgress';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { createPortal } from 'react-dom';
import { FormLabel } from '@material-ui/core';
import { IPresenterProps, ISolicitud, IVehiculo } from './types';
import StatusSMS from '../statusSMS';
import ConfirmModal from './ModalConfirm';
import dayjs from 'dayjs';
import timezone from "dayjs/plugin/timezone"
import utc from "dayjs/plugin/utc";
import "./styles.css";

dayjs.extend(utc);
dayjs.extend(timezone);

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1)
        },
    },

    linearProgress: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
        marginTop: 10,
        marginBottom: 10,
    }
}));

export default (props: IPresenterProps) => {
    const classes = useStyles()

    const validarChasis = () => {

        const regEx = /^[a-zA-Z0-9]{12,20}$/;

        const esValido = regEx.test(props.vehiculo.chasis) ? true : false;

        return esValido;
    }

    const [disabledDeleteAll, setDisabledDeleteAll] = useState(true);
    const [instaladoHace, setInstladoHace] = useState(0);
    const [showModalConfirm, setShowModalConfirm] = useState<"" | "delete">("");

    useEffect(() => {
        if (props.vehiculo.fecha_instalacion) {
            const interval = setInterval(() => {
                const diferencia = dayjs().diff(dayjs(props.vehiculo.fecha_instalacion).tz('America/Guayaquil'), "minutes");
                setInstladoHace(Number(diferencia))
            }, 1000);
            return () => clearInterval(interval)
        }
    }, [props.vehiculo.fecha_instalacion]);

    useEffect(() => {
        if (disabledDeleteAll && (instaladoHace >= 40)) {
            setDisabledDeleteAll(prevState => !prevState);
        }
    }, [disabledDeleteAll, instaladoHace]);

    const validarDispositivo = () => {

        if (props.vehiculo.placa === '') {
            return validarChasis();
        } else if (props.vehiculo.chasis === '') {
            return true;
        } else if (props.vehiculo.chasis !== '') {
            return validarChasis();
        }

    }

    function parseDate(dateString: string): dayjs.Dayjs {
        const formats = ["YYYY-MM-DDTHH:mm", "YYYY-MM-DD H:mm"];
        return dayjs.utc(dateString, formats.find(format => dayjs(dateString, format, true).isValid()));
      }

    return (
        <div>
            <Toolbar variant="regular" style={{ justifyContent: 'center' }}>
                <Typography variant="h5" color="inherit">
                    CONFIGURACIÓN DE DISPOSITIVOS
                </Typography>
            </Toolbar>
            {props.solicitud &&
                <Grid>
                    <Grid container justifyContent={"center"} >
                        <Grid className={classes.root} item sm>
                            <Container >
                                <TextField
                                    style={{
                                        width: '100%'
                                    }}
                                    required
                                    label="Número de instalación"
                                    value={props.solicitud.id}
                                    disabled
                                />
                                <TextField
                                    style={{
                                        width: '100%'
                                    }}
                                    label="Cliente"
                                    disabled
                                    value={`${props.cliente.nombres} ${props.cliente.apellidos}`}
                                    variant="outlined" />
                                {/* <TextField // se descidio oculata esto con JF
                                    onChange={(event) => {
                                        props.setEmail(event.target.value);
                                    }}
                                    required
                                    style={{
                                        width: '100%'
                                    }}
                                    label="Email"
                                    disabled={true}
                                    value={props.cliente.email}
                                    variant="outlined" /> */}
                                <TextField
                                    style={{
                                        width: '100%'
                                    }}
                                    label="Fecha de Vencimiento"
                                    type="datetime-local"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                   value={ parseDate(props.vehiculo.fecha_expiracion ?? '').format('YYYY-MM-DDTHH:mm')}
                                   
                                    disabled
                                    variant="outlined"
                                />
                                <TextField
                                    style={{
                                        width: '100%'
                                    }}
                                    label="Vehículo"
                                    disabled
                                    value={`${props.vehiculo.marca} ${props.vehiculo.modelo} ${props.vehiculo.anio}`}
                                    variant="outlined" />
                            </Container>
                            <Container >
                                <TextField
                                    style={{
                                        width: '100%'
                                    }}
                                    label={"Número de serie"}
                                    placeholder="Ingrese el número de serie"
                                    value={props.dispositivo.serial}
                                    onChange={(e) => {
                                        props.setNumeroSerie(e.target.value);
                                    }}
                                    disabled={props.solicitud?.estado !== null}
                                    onKeyPress={e => e.key === 'Enter' && props.onObtenerDispositivo()}
                                    error={props.errorObtnerDispositivo ? true : false}
                                    helperText={props.errorObtnerDispositivo} />
                                {props.loading !== 'device' && props.solicitud?.estado === null && <Button variant={"contained"} color={"primary"} onClick={() => props.onObtenerDispositivo()} disabled={props.loading !== '' ? true : false}>Buscar</Button>}
                                {props.loading === 'device' && <div className={classes.linearProgress}>
                                    <LinearProgress />
                                </div>}

                                <TextField style={{
                                    width: '100%'
                                }}
                                    label={"SIM ID"}
                                    disabled={true}
                                    value={props.dispositivo.simId || ''}
                                    variant="outlined" />
                                <TextField
                                    required
                                    style={{
                                        width: '100%'
                                    }}
                                    label="Placa del vehículo"
                                    value={props.vehiculo.placa || ''}
                                    disabled={props.solicitud?.estado !== null}
                                    onChange={(e) => {
                                        const withNoSpaces = e.target.value.replace(/\s+/g, '');
                                        props.setPlaca(withNoSpaces);
                                    }} />
                                <TextField
                                    style={{
                                        width: '100%'
                                    }}
                                    label="Número de Chasis (Obligatorio solo si no hay placa)"
                                    value={props.vehiculo.chasis || ''}
                                    disabled={props.solicitud?.estado !== null}
                                    onChange={(e) => {
                                        const withNoSpaces = e.target.value.replace(/\s+/g, '');
                                        props.setChasis(withNoSpaces);
                                    }} />
                                <TextField
                                    required
                                    style={{
                                        width: '100%',
                                        marginBottom: 20
                                    }}
                                    type="number"
                                    label="Kilometraje"
                                    value={props.vehiculo.kilometraje}
                                    disabled={props.solicitud?.estado !== null}
                                    onChange={(e) => props.setKilometraje(e.target.value)} />
                                {props.solicitud.estado === 'configurado' && <Grid>
                                    <Typography>Funciones Instaladas</Typography>
                                    <Container >
                                        <FormLabel style={{ paddingLeft: 10 }}>Apertura de puertas</FormLabel>
                                        <Checkbox
                                            onClick={() => props.setAperturaDePuertas(!props.vehiculo.apertura_puertas)}
                                            checked={!!props.vehiculo.apertura_puertas}
                                        />
                                    </Container>
                                    <Container>
                                        <FormLabel style={{ paddingLeft: 10 }}>Bloqueo de Motor</FormLabel>
                                        <Checkbox
                                            onClick={() => props.setBloqueoMotor(!props.vehiculo.bloqueo_motor)}
                                            checked={!!props.vehiculo.bloqueo_motor}
                                        />
                                    </Container>
                                    <Container >
                                        <FormLabel style={{ paddingLeft: 10 }}>Boton de panico</FormLabel>
                                        <Checkbox
                                            onClick={() => props.setBotonDePanico(!props.vehiculo.boton_panico)}
                                            checked={!!props.vehiculo.boton_panico}
                                        />
                                    </Container>
                                    <TextField style={{
                                        width: '100%'
                                    }}
                                        required
                                        label="Nombre del instalador"
                                        id="instalador"
                                        value={props.dispositivo.instalador}
                                        onChange={(e) => props.setInstalador(e.target.value)} />
                                    <TextField
                                        style={{
                                            width: '100%'
                                        }} label="Notas (Opcional)"
                                        value={props.vehiculo.notas}
                                        onChange={(e) => props.setNotas(e.target.value)}
                                        multiline
                                        variant="outlined"
                                    />
                                </Grid>}
                                <span style={{ color: 'red', marginTop: 20, fontSize: 16, fontWeight: 'bold' }}>{props.errorConfigurar}</span><br />
                                {props.loading !== 'setup' && props.solicitud?.estado === null && <Button onClick={() => validarDispositivo() ? props.onConfigurarDispositivo() : alert("Número de chasis inválido")} variant={"contained"} color={"primary"} disabled={props.cliente.email === '' || props.dispositivo.simId === '' || (props.vehiculo.placa === '' && props.vehiculo.chasis === '' || props.solicitud.estado !== null)}>Iniciar Configuración</Button>}

                            </Container>
                        </Grid>
                        <Grid className={classes.root} item sm>
                            {props.vehiculo.fecha_instalacion &&
                                <Button disabled={disabledDeleteAll} onClick={() => setShowModalConfirm("delete")}>
                                    Eliminar Instalación {instaladoHace < 40 && `- Disponible en  ${40 - instaladoHace} min(s)`}
                                </Button>}
                            {props.dispositivo.emnifyId && props.dispositivo.emnifyId !== '' && props.dispositivo && <StatusSMS dispositivo={props.dispositivo} vehiculo={props.vehiculo} onUpdateFuncionesInstaladas={props.onUpdateFuncionesInstaladas} onUpdateEstado={props.onUpdateEstado} onEnviarComandoGPRS={props.onEnviarComandoGPRS} />}
                            <Container>
                                {props.loading !== 'setup' && props.solicitud?.estado == 'configurado' && <Button variant='contained' color='primary' onClick={props.onFinalizarConfiguracion} disabled={!pruebasRealizadas(props.solicitud, props.vehiculo)}>Finalizar Instalación</Button>}
                            </Container>
                            <Container>
                                {props.loading === 'setup' &&
                                    <div style={{ display: 'flex', width: '100%', flexDirection: 'column', alignItems: 'center' }}>
                                        <h4>Procesando</h4>
                                        <div className={classes.linearProgress}>
                                            <LinearProgress />
                                        </div>
                                    </div>}
                            </Container>
                        </Grid>
                    </Grid>
                    <div className="clipping-container">
                        {showModalConfirm === "delete" && createPortal(
                            <ConfirmModal onClose={() => setShowModalConfirm("")}
                                onAceptar={() => {
                                    props.onEliminarInstalacion();
                                    setShowModalConfirm("")
                                }} />,
                            document.body
                        )}

                    </div>
                </Grid>}
            {!props.solicitud &&
                <Container maxWidth={'sm'}>
                    <TextField
                        style={{
                            width: '100%'
                        }}
                        required
                        label="Ingrese el Número de instalación"
                        value={props.solicitudId}
                        onChange={(e) => {
                            props.setNumeroInstalacion(e.target.value);
                        }}
                    />
                    <TextField
                        style={{
                            width: '100%',
                            marginBottom: 20
                        }}
                        required
                        label="Ingrese la clave"
                        value={props.solicitudClave}
                        onChange={(e) => {
                            props.setPassword(e.target.value);
                        }}
                        onKeyPress={e => e.key === 'Enter' && props.onObtenerSolicitud()}
                        error={props.errorSolicitud ? true : false}
                        helperText={props.errorSolicitud} />
                    {props.loading !== 'customer' && <Button variant={"contained"} color={"primary"} onClick={() => props.onObtenerSolicitud()} disabled={props.loading !== '' ? true : false}>Acceder</Button>}
                    {props.loading === 'customer' && <div className={classes.linearProgress}>
                        <LinearProgress />
                    </div>}
                </Container>
            }
        </div>
    );
}

function pruebasRealizadas(solicitud: ISolicitud, vehiculo: IVehiculo): boolean {
    var puertas, bloqueo = false;
    if (vehiculo.apertura_puertas || vehiculo.bloqueo_motor) {
        if (vehiculo.apertura_puertas) {
            if (solicitud.prueba_puertas) {
                puertas = true;
            }
        } else {
            puertas = true;
        }
        if (vehiculo.bloqueo_motor) {
            if (solicitud.prueba_desbloqueo && solicitud.prueba_bloqueo) {
                bloqueo = true;
            }
        } else {
            bloqueo = true;
        }
        if (puertas && bloqueo)
            return true;
        return false;
    }

    return true;
}